import React, { useEffect, useState } from "react"

import PhoneDots from "../../static/images/phone-dots.svg"
import YellowDot from "../../static/images/large-yellow-dot.svg"
import ImgPhone from "../../static/images/mockup-frame.png"
import ImgScreen from "../../static/images/home.jpg"

export const ImageScroller = () => {
  const [shouldAnimate, setAnimate] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimate(true)
    }, 500)

    return () => clearTimeout(timeout)
  })

  const classes = ["screen"]

  if (shouldAnimate) classes.push("animate")

  return (
    <>
      <div
        className="preview"
        style={{ backgroundImage: `url(${PhoneDots}),url(${YellowDot})` }}
      >
        <img src={ImgPhone} alt="phone displaying app" className="phone" />
        <div className={classes.join(" ")}>
          <img src={ImgScreen} alt="app screenshot" />
        </div>
      </div>
    </>
  )
}
