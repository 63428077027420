import React from "react"
import { useInView } from "react-intersection-observer"

export const AnimateOnEntry = ({ children }) => {
  const { ref, inView } = useInView({
    threshold: 0.6,
    triggerOnce: true,
  })

  const animationClasses = inView ? ["fadeIn"] : ["hidden"]

  return (
    <div ref={ref}>
      <div className={animationClasses.join(" ")}>{children}</div>
    </div>
  )
}
